<template>
  <div>
    <b-modal
      id="modal-news-center"
      :hide-footer="true"
      modal-class="custom-modal1"
      @shown="onOpen"
      @hidden="onClose"
    >
      <template slot="modal-header" slot-scope="{ close }">
        <div class="header-content">
          <div class="headline">
            {{ $t("news.main_headline") }}
          </div>
          <div class="filter-container">
            <div
              class="filter-button"
              :class="isFilterActive ? 'active' : 'inactive'"
              @click="showFilterMenu"
            ></div>
            <div v-if="filterOpen" class="filter-window bg-window">
              <div
                class="btn-close-filter clickable bg-img"
                @click="closeFilterMenu"
              ></div>
              <ul>
                <li
                  v-for="filter in filters"
                  :key="filter.title"
                  class="filter-entry clickable"
                  :class="[
                    filter.userNews ? 'green' : 'primary-accent',
                    filter.title === activeFilter ? 'active' : '',
                  ]"
                  @click="selectFilter(filter.title)"
                >
                  {{ $t(filter.title) }}
                </li>
              </ul>
            </div>
          </div>

          <div
            v-if="isFilterActive"
            class="filter-cancel-button clickable bg-img"
            @click="removeAllFilters"
          ></div>

          <a href="#" class="close-button" @click="close()"> </a>
        </div>
      </template>

      <template slot="default">
        <b-row class="main-content">
          <b-col
            v-if="dataTopNews.length > 0"
            cols="4"
            class="left-side bg-window"
          >
            <div class="top-news-headline to-center-abs yellow-text">
              {{ $t("news.top_news") }}
            </div>
            <top-news :data="dataTopNews"></top-news>
          </b-col>

          <b-col class="right-side bg-window ms-3">
            <div v-if="dataCommonNews.length > 0" class="news-list container">
              <NewsListEntry
                v-for="news in dataCommonNews"
                :key="news.id"
                :entry="news"
                :common-news-only="dataTopNews.length === 0"
              />
            </div>
            <div v-else class="w-100 text-center mt-5">
              <span class="text-uppercase medium">
                {{ $t("news.no_news_placeholder") }}</span
              >
            </div>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import TopNews from "@/components/notification/top-news";
import NewsListEntry from "@/components/notification/news-list-entry";
export default {
  name: "ModalNewsCenter",
  components: { TopNews, NewsListEntry },
  props: {},
  data() {
    return {
      filterOpen: false,
      error: false,
      currentCarouselSite: 0,
      activeFilter: "",
    };
  },
  computed: {
    filters() {
      return this.$store.getters["news/newsCategories"];
    },
    isFilterActive() {
      return this.activeFilter !== "";
    },
    dataCommonNews() {
      let news = this.$store.getters["news/availableMessages"];

      //filter
      let result = [];
      news.forEach((newsData) => {
        if (
          newsData.news_type &&
          newsData.news_type.title === this.activeFilter
        ) {
          result.push(newsData);
        }
      });

      if (result.length) {
        return result;
      }

      return news;
    },
    dataTopNews() {
      return this.$store.state.news.messages.filter(
        (news) => news.priority > 0
      );
    },
  },

  methods: {
    showFilterMenu() {
      this.filterOpen = !this.filterOpen;
    },

    selectFilter(title) {
      this.activeFilter = title;
    },
    closeFilterMenu() {
      this.filterOpen = false;
    },
    removeAllFilters() {
      this.activeFilter = "";
    },

    onOpen() {
      this.dataCommonNews.forEach((news) => {
        this.$store.commit("news/markAsRead", news.id);
      });
      this.$store.dispatch("news/markUserNewsAsRead");
    },
    onClose() {},
  },
};
</script>
<style lang="scss">
$modal-width: 1110px;
$modal-height: 600px;

#modal-news-center {
  .modal-dialog {
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;
    top: 90px;

    @media (max-height: 700px) {
      transform: scale(0.8);
      top: 0px;
    }
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: transparent;
    background-size: 100% 100%;
  }
}
</style>

<style scoped lang="scss">
#modal-news-center {
  .bg-window {
    background-image: linear-gradient(#0e1c24, #173242);
    border: 2px solid $text-accent;
    border-radius: 8px;
    box-shadow: 0 2px 10px $drop-shadow-dark, 0 2px 10px $drop-shadow-dark,
      0 2px 4px $drop-shadow-dark;
  }
  .modal-header {
    .header-content {
      width: 100%;
      position: absolute;
      top: -30px;

      .headline {
        font-family: Ubuntu-Medium;
        font-size: 36px;
        color: $primary-accent;
        text-transform: uppercase;
        position: absolute;
        left: -6px;
        top: 6px;
      }
      .filter-container {
        position: absolute;
        right: 230px;

        .filter-button {
          width: 64px;
          height: 64px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          cursor: pointer;

          &:hover {
            filter: $hover-shadow;
          }

          &.active {
            background-image: url(~@/assets/img/notification/icn-filter-menu-active.png);
          }
          &.inactive {
            background-image: url(~@/assets/img/notification/icn-filter-menu-inactive.png);
          }
        }
        .filter-window {
          position: absolute;
          z-index: 100;
          top: 0px;
          right: 70px;
          padding: 33px 20px 0 0;
          text-align: left;

          .filter-entry {
            font-size: 21px;
            font-family: Ubuntu;
            text-transform: uppercase;
            line-height: 2.6;
            cursor: pointer;
            list-style-type: none;
            white-space: nowrap;

            &.green {
              color: #43ff00;
            }
            &.active {
              color: #ffa900;
            }
          }

          .btn-close-filter {
            position: absolute;
            z-index: 100;
            top: 20px;
            right: 20px;
            width: 20px;
            height: 20px;
            background-image: url(~@/assets/img/notification/btn-filter-panel-close.svg);
          }
        }
      }

      .filter-cancel-button {
        position: absolute;
        right: 165px;
        top: 16px;
        height: 32px;
        width: 32px;
        background-image: url(~@/assets/img/notification/btn-close-filter.svg);
      }

      .close-button {
        position: absolute;
        right: 15px;
        top: 10px;
        width: 40px;
        height: 40px;
        max-width: none;
        background: url(~@/assets/img/notification/btn-close-newscenter.png)
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .main-content {
    height: 504px;
    flex-wrap: nowrap;

    .left-side {
      padding: 0 20px;

      .top-news-headline {
        font-family: Ubuntu-Medium;
        font-size: 36px;
        text-transform: uppercase;
        top: 15px;
      }
    }

    .right-side {
      padding: 11px 13px 15px 13px;

      .news-list {
        overflow-x: hidden;
        width: 100%;
        height: 100%;
        padding-top: 8px;
        &::-webkit-scrollbar {
          height: 12px;
          width: 12px;
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background: $background-dark-overlay-30;
          -webkit-border-radius: 1ex;
          -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
        }

        &::-webkit-scrollbar-corner {
          background: #000;
        }
      }
    }
  }
}
</style>
