<template>
  <b-modal
    id="modal-mail-confirm"
    modal-class="alert-window"
    :hide-footer="true"
  >
    <template #modal-header>
      <div class="d-flex flex-column align-items-center">
        <div
          id="newsletter-icon"
          class="bg-img"
          :style="{
            backgroundImage:
              'url(' + $whitelabel.assets.logos.newsletterVisual + ')',
          }"
        ></div>

        <div
          role="button"
          href="#"
          class="close-button"
          @click="$bvModal.hide('modal-mail-confirm')"
        ></div>
        <DecoCorners color-variant="primary-accent" />
        <div class="super-headline3 bold">
          {{ $t("alerts.email_confirm.prompt_instant.heading") }}
        </div>
      </div>
    </template>
    <template slot="default">
      <div class="modal-contents position-relative">
        <p class="modal-prompt mt-3">
          {{ $t("alerts.email_confirm.prompt_instant.body") }}
        </p>
        <p class="bold">
          {{ $t("alerts.email_confirm.your_reward") }}
        </p>
        <div
          id="reward-display"
          class="d-inline-flex align-items-center justify-content-around bg-img"
        >
          <BaseAsset asset="primary" style="width: 47px; height: 47px" />
          <span class="primary-accent bold">{{
            formatNumber(confirmReward)
          }}</span>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import DecoCorners from "@/components/base/deco-corners.vue";
import BaseAsset from "@/components/base/base-asset.vue";
export default {
  name: "ModalMailConfirm",
  components: { DecoCorners, BaseAsset },
  computed: {
    confirmReward() {
      return this.$store.state.generalConfig.settings["mail.verify.reward"]
        .gold;
    },
    tripleCoinUrl() {
      return this.$whitelabel.currencies.primary.triple;
    },
  },
};
</script>
<style lang="scss">
#modal-mail-confirm {
  margin-top: 100px;
  .close-button {
    right: -30px;
    top: -29px;
    width: 60px;
    height: 60px;
  }
  .modal-header {
    border: none !important;
    justify-content: center;
    text-transform: uppercase;
    font-size: 44px;
    .super-headline3 {
      background-image: linear-gradient(to bottom, #f7ffff, #ffee51);
      font-family: "Ubuntu", sans-serif !important;
    }
  }
  .modal-dialog {
    max-width: 734px;
  }
  .modal-content {
    color: white;
    text-align: center;
    background-color: transparent;
    background-image: linear-gradient(
        $bg-gradient-deep-ocean,
        $bg-gradient-navy-blue
      ),
      linear-gradient($bg-gradient-navy-blue, $bg-gradient-deep-ocean);
    border: double 1px transparent;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-size: 100% 100%;
  }

  .modal-contents {
    width: 100%;

    min-height: 128px;
    .super-headline3 {
      font-size: 28.8px;
      background-image: linear-gradient(
        to bottom,
        #f7ffff,
        #ffee51,
        $bg-gradient-golden
      );
      filter: drop-shadow(0 0 6px $drop-shadow-dark)
        drop-shadow(0 0 2px $drop-shadow-dark);
    }
    .modal-prompt {
      padding: 0 100px;
      font-size: 24px;
    }
    #reward-display {
      width: 383px;
      height: 62px;
      background-image: url(~@/assets/img/common/reward-display.svg);
      margin-bottom: 96px;
      img {
        height: 47px;
      }
      span {
        font-size: 48px;
        text-shadow: 0 0 10px rgba(77, 229, 255, 0.53),
          0 0 2px $drop-shadow-dark, 0 0 2px $btn-drop-shadow-blue-modern;
      }
    }
  }
  #newsletter-icon {
    width: 265px;
    height: 229px;
    z-index: 10;
  }
}
</style>
