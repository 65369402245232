<template>
  <b-modal
    id="modal-mail-confirm-reminder"
    modal-class="alert-window"
    :hide-footer="true"
    @show="setUserEmail"
  >
    <template #modal-header>
      <div
        role="button"
        href="#"
        class="close-button"
        @click="$bvModal.hide('modal-mail-confirm-reminder')"
      ></div>
      <DecoCorners color-variant="primary-accent" />
      <div
        id="newsletter-icon"
        class="to-center-abs bg-img"
        :style="{ backgroundImage: 'url(' + tripleCoinUrl + ')' }"
      ></div>
    </template>
    <template slot="default">
      <div class="modal-contents">
        <div class="super-headline3">{{ $t("profile.email_confirm") }}</div>

        <p class="modal-prompt mt-3">
          {{
            $t("alerts.email_confirm.prompt_24h", {
              0: confirmReward / 1000000,
            })
          }}
        </p>
        <form @submit.prevent="handleFormSubmit">
          <input
            id="email-verify-input"
            v-model="userEmail"
            type="text"
            maxlength="60"
            class="d-inline-flex align-items-center bg-img primary-accent medium px-3"
          />
          <button
            id="email-verify-submit"
            type="submit"
            :disabled="!validEmail"
            class="ok-button-green text-white bold p-0 d-inline-flex align-items-center justify-content-center"
            :class="!validEmail ? 'disabled' : ''"
          >
            {{ $t("alerts.email_confirm.button_24h") }}
          </button>
        </form>
      </div>
    </template>
  </b-modal>
</template>

<script>
import DecoCorners from "@/components/base/deco-corners.vue";
import * as ProfileAPI from "@/API/profile.js";
import i18next from "i18next";
export default {
  name: "ModalMailConfirmReminder",
  components: { DecoCorners },
  data() {
    return {
      userEmail: "",
    };
  },
  computed: {
    tripleCoinUrl() {
      return this.$whitelabel.currencies.primary.triple;
    },
    confirmReward() {
      return this.$store.state.generalConfig.settings[
        "mail.verify.reminder.reward"
      ].gold;
    },
    validEmail() {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.userEmail);
    },
  },
  methods: {
    setUserEmail() {
      this.userEmail = this.$store.getters["user/currentUser"].email;
    },
    handleFormSubmit() {
      if (
        this.userEmail &&
        this.userEmail === this.$store.getters["user/currentUser"].email
      ) {
        this.confirmEmail();
      } else {
        this.changeEmail();
      }
    },
    changeEmail() {
      let localeQuery =
        this.$store.getters.getLocale +
        "_" +
        this.$store.getters.getLocale.toUpperCase();
      ProfileAPI.updateEmail(this.userEmail, localeQuery)
        .then(() => {
          this.$store.commit("popups/setAlertBannerContent", {
            alertText: this.$t("profile.email_confirm.alert", {
              0: this.userEmail,
            }),
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.data.details) {
            this.$store.commit("popups/setAlertBannerContent", {
              alertText: e.data.details[0],
              alertHeading: this.$t("alerts.title.error"),
              type: "danger",
              variant: "danger",
            });
          } else {
            this.displayErrorMessage();
          }
        })
        .finally(() => {
          this.$bvModal.hide("modal-mail-confirm-reminder");
          this.userEmail = "";
        });
    },
    confirmEmail() {
      ProfileAPI.confirmEmail()
        .then(() => {
          this.$store.commit("popups/setAlertBannerContent", {
            alertText: this.$t("profile.email_confirm.alert", {
              0: this.$store.getters["user/currentUser"].email,
            }),
          });
        })
        .catch((e) => {
          if (e.data) {
            let errorMessage;
            //get error message from server
            let errorDetails = e.data.details[0]
              .split(" ")
              .join("_")
              .split(".")
              .join("")
              .toLowerCase();
            if (i18next.exists("profile.email_confirm.error." + errorDetails)) {
              errorMessage = this.$t(
                "profile.email_confirm.error." + errorDetails
              );
            } else {
              this.displayErrorMessage();
            }
            //fallback
            this.$store.commit("popups/setAlertBannerContent", {
              alertText: errorMessage,
              alertHeading: this.$t("alerts.title.error"),
              variant: "danger",
              type: "danger",
            });
          }
        })
        .finally(() => {
          this.$bvModal.hide("modal-mail-confirm-reminder");
          this.userEmail = "";
        });
    },
  },
};
</script>
<style lang="scss">
#modal-mail-confirm-reminder {
  margin-top: 50px;
  .close-button {
    right: -30px;
    top: -29px;
    width: 60px;
    height: 60px;
  }
  .modal-header {
    border: none !important;
    justify-content: center;
    text-transform: uppercase;
    font-size: 44px;

    #newsletter-icon {
      width: 265px;
      height: 165px;
      top: -82px;
      z-index: 10;
    }
  }
  .modal-dialog {
    max-width: 734px;
    margin-top: 75px;
  }
  .modal-content {
    color: white;
    text-align: center;
    background-color: transparent;
    background-image: linear-gradient(
        $bg-gradient-deep-ocean,
        $bg-gradient-navy-blue
      ),
      linear-gradient($bg-gradient-navy-blue, $bg-gradient-deep-ocean);
    border: double 1px transparent;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-size: 100% 100%;
    padding-top: 55px;
  }

  .modal-contents {
    width: 100%;

    min-height: 128px;
    .super-headline3 {
      background-image: linear-gradient(to bottom, #f7ffff, #ffee51);
      font-size: 44px;
      font-family: Ubuntu-bold, sans-serif !important;
    }

    .modal-prompt {
      padding: 0 100px;
      font-size: 24px;
    }
    #email-verify-input {
      width: 639px;
      height: 62px;
      background-image: url(~@/assets/img/backgrounds/input-bg.svg);
      margin-bottom: 24px;
      background-color: transparent;
      border: 0;
      font-size: 32px;
      text-shadow: 0 0 10px rgba(77, 229, 255, 0.53), 0 0 2px $drop-shadow-dark,
        0 0 2px $btn-drop-shadow-blue-modern;
      &:focus-visible {
        outline: 0;
        filter: drop-shadow(0 0 4px $text-accent);
      }
    }
    #email-verify-submit {
      width: 508px;
      height: 48px;
      border-radius: 24px;
      margin-bottom: 31px;
      font-size: 20.6px;
      text-shadow: 0 2px 3px black;
      &.disabled {
        filter: grayscale(100);
        pointer-events: none;
      }
    }
  }
}
</style>
