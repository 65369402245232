<template>
  <div>
    <div class="modal-pagetitle text-center text-white text-uppercase">
      Informationen zu Kleine Krone
    </div>
    <div class="modal-prompt">
      <p>Liebe Spielerinnen und Spieler,</p>

      <p>
        Es gibt Neuigkeiten: Das Angebot von Kleine Krone wird ab sofort von der
        {{ $whitelabel.branding.companyName }} als
        {{ $whitelabel.branding.productName }} betrieben.
      </p>
      <p>
        Für euch bleibt alles beim Alten. Wir bieten euch an: Ihr könnt eure
        Profile komplett zu {{ $whitelabel.branding.productName }} übertragen
        und habt lediglich einen neuen Vertragspartner. Dazu müsst ihr nur die
        AGB akzeptieren. Weitere Hinweise findet ihr in den
        <button
          id="tos-text-button"
          class="text-button button-empty text-decoration-underline regular"
        >
          Allgemeinen Geschäftsbedingungen
        </button>
        und in der
        <button
          id="privacy-text-button"
          class="text-button button-empty text-decoration-underline regular"
        >
          Datenschutzerklärung</button
        >.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalToSPage1",
  mounted() {
    this.bindButtonClickTos();
    this.bindButtonClickPrivacy();
  },
  updated() {
    this.bindButtonClickTos();
    this.bindButtonClickPrivacy();
  },
  methods: {
    bindButtonClickTos() {
      const button = document.getElementById("tos-text-button");
      if (button) {
        button.removeEventListener("click", this.openToS); // Add new listener
        button.addEventListener("click", this.openToS); // Add new listener
      } else {
        console.log("no button tos");
      }
    },
    bindButtonClickPrivacy() {
      const button = document.getElementById("privacy-text-button");
      if (button) {
        button.removeEventListener("click", this.openPrivacy); // Add new listener

        button.addEventListener("click", this.openPrivacy); // Add new listener
      } else {
        console.log("no button tos");
      }
    },
    openToS() {
      this.$emit("open-tos");
    },
    openPrivacy() {
      this.$emit("open-privacy");
    },
  },
};
</script>
