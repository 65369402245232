<template>
  <div class="menu-list-entry">
    <b-row
      class="news-entry mx-auto w-100 clickable"
      align-h="center"
      @click="openLocation"
    >
      <b-col
        :cols="commonNewsOnly ? 2 : 3"
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <img
          draggable="false"
          class="news-icon"
          :src="newsEntryImg"
          :class="slug"
        />
      </b-col>
      <b-col class="ps-0 pt-2">
        <div class="news-text">
          <div class="headline">
            <span v-if="entry.priority > 0" class="green-text text-uppercase">{{
              $t("news.new_tag")
            }}</span>
            <span class="normal-text" v-html="$t(entry.title)"></span>
          </div>
          <div
            class="subtext"
            :class="[entry.content.length > 90 ? 'smol' : '']"
          >
            <span v-html="newsEntryText"> </span>
            <div
              v-if="entry.payload && entry.payload.items"
              class="d-inline-flex align-items-center"
            >
              <RewardItem
                v-for="reward in entry.payload.items"
                :key="reward.item.id"
                :item="reward.item"
                class="me-3"
              ></RewardItem>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <div
      class="news-closer clickable"
      @click="$store.dispatch('news/markNewsAsDeleted', entry.id)"
    ></div>
  </div>
</template>
<script>
import RewardItem from "@/components/base/reward-item.vue";
export default {
  name: "NewsListEntry",
  components: {
    RewardItem,
  },
  props: {
    entry: {
      type: Object,
      required: true,
    },
    commonNewsOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      links: [
        {
          name: "loyalties",
          url: "/profile/loyalty",
        },

        {
          name: "leagues",
          url: "/league",
        },
        {
          name: "team_league",
          url: "/teams",
        },
        {
          name: "friends",
          url: "/teams/friends",
        },

        {
          name: "chat",
          url: "/teams/chat",
        },
        {
          name: "achievements",
          url: "/profile/achievements",
        },
        {
          name: "champions",
          url: "/champions",
        },
      ],
    };
  },

  computed: {
    newsEntryImg() {
      if (
        this.entry.news_type &&
        this.entry.news_type.slug === "ingameevents"
      ) {
        return this.eventImg;
      } else if (!this.isUserNews && this.entry.news_type.banner.length > 0) {
        return this.entry.news_type.banner[0].url;
      } else return this.userNewsImg;
    },
    isUserNews() {
      return this.$store.state.news.userMessages.indexOf(this.entry) >= 0;
    },
    userNewsImg() {
      switch (this.entry.title) {
        case "news.champions.battle_end.title":
          return require("@/assets/img/notification/champions-matchend.png");
        case "news.champions.battle_matchday_end.title":
          return require("@/assets/img/notification/champions-matchweekend.png");
        case "news.league.ascend.title":
          return require("@/assets/img/notification/adel-up.png");
        case "news.league.descend.title":
          return require("@/assets/img/notification/adel-down.png");
        case "news.achievement.complete.title":
          return require("@/assets/img/notification/achievement.png");
        case "news.friends.confirmed.title":
          return require("@/assets/img/notification/friend-complete.png");
        case "news.friends.request.title":
          return require("@/assets/img/notification/friend-complete.png");
        case "news.loyalty.levelup.title":
          return require("@/assets/img/loyalty/group-21.png");
        case "news.mission.complete.title":
          return require("@/assets/img/mission/chests/chest-1-wood.png");
        case "news.kronenpass.reward.title":
          return require("@/assets/img/events/menu/icn-winterbonusjagd.png");
        case "payment.done.title":
          return this.$whitelabel.currencies.primary.triple;
        case "news.ingameevent.activated.title":
          return this.eventImg;
        case "news.ingameevent.reward.title":
          return this.eventImg;
        default:
          return require("@/assets/img/notification/icn-nc-lobby.png");
      }
    },
    eventImg() {
      let eventType;
      if (this.entry.payload && this.entry.payload[2]) {
        eventType = this.entry.payload[2];
      } else {
        return require("@/assets/img/events/lock-active.png");
      }

      if (eventType.endsWith("LevelUpIngameEventType")) {
        return require("@/assets/img/events/menu/icn-levelup.png");
      } else if (eventType.endsWith("SlotSpinIngameEventType")) {
        return require("@/assets/img/events/menu/icn-spinwin.png");
      }
      if (eventType.endsWith("SportBetIngameEventType")) {
        return require("@/assets/img/events/menu/icn-tipking.png");
      } else {
        return require("@/assets/img/notification/icn-nc-lobby.png");
      }
    },

    slug() {
      if (!this.isUserNews) {
        return this.entry.news_type.slug;
      }

      switch (this.entry.title) {
        case "news.league.ascend.title":
          return "user_league";
        case "news.league.descend.title":
          return "user_league";
        case "news.achievement.complete.title":
          return "achievement";
        case "news.friends.confirmed.title":
          return "friend-confirm";
        case "news.loyalty.levelup.title":
          return "loyalty";
      }

      return "unknown";
    },

    currentLink() {
      const result = this.links.find((link) => link.name === this.slug);
      if (!result) {
        return;
      }

      return result.url;
    },
    newsEntryLinks() {
      if (!this.entry.links || this.entry.links.length === 0) return null;
      return Object.keys(this.entry.links);
    },
    newsEntryText() {
      let text;

      if (!this.entry.payload || !this.entry.payload[0]) {
        text = this.$t(this.entry.content);
      } else if (
        this.entry.title === "news.ingameevent.activated.title" ||
        this.entry.title === "news.ingameevent.reward.title"
      ) {
        text = this.$t(this.entry.content, { 0: this.entry.payload[1] });
      } else if (
        this.entry.title === "news.friends.confirmed.title" ||
        this.entry.title === "news.friends.request.title"
      ) {
        text = this.$t(this.entry.content, { 0: this.entry.payload[0] });
      } else if (this.entry.title === "news.achievement.complete.title") {
        text = this.$t(this.entry.content, {
          0: this.entry.payload[0] + 1,
          1: this.$t(`achievements.achievement_title.${this.entry.payload[1]}`),
        });
      } else {
        text = this.$t(this.entry.content);
      }

      // Replace newlines with <br>
      return text.replace(/\r?\n/g, "<br>");
    },
  },

  methods: {
    openLocation() {
      if (this.newsEntryLinks) {
        this.$store.dispatch("news/markNewsAsDeleted", this.entry.id);
        this.$router.push(this.newsEntryLinks[0]);
        this.$bvModal.hide("modal-news-center");
      } else if (this.currentLink) {
        this.$store.dispatch("news/markNewsAsDeleted", this.entry.id);
        this.$router.push(this.currentLink);
        this.$bvModal.hide("modal-news-center");
      } else {
        this.doClickAction();
      }
    },
    doClickAction() {
      //champions
      if (this.entry.title.includes("news.champions")) {
        this.$store.dispatch("news/markNewsAsDeleted", this.entry.id);
        this.$router.push("/champions");
        this.$bvModal.hide("modal-news-center");
        return;
      }

      //league
      if (this.entry.title.includes("news.league")) {
        this.$store.dispatch("news/markNewsAsDeleted", this.entry.id);
        this.$router.push("/league");
        this.$bvModal.hide("modal-news-center");
        return;
      }

      //loyalty
      if (this.entry.title.includes("news.loyalty")) {
        this.$store.dispatch("news/markNewsAsDeleted", this.entry.id);
        this.$router.push("/profile/loyalty");
        this.$bvModal.hide("modal-news-center");
        return;
      }

      //achievement
      if (this.entry.title.includes("news.achievement")) {
        this.$store.dispatch("news/markNewsAsDeleted", this.entry.id);
        this.$router.push("/profile/achievements");
        this.$bvModal.hide("modal-news-center");
        return;
      }

      //winter bonus hunt
      if (this.entry.title.includes("news.kronenpass")) {
        this.$store.dispatch("news/markNewsAsDeleted", this.entry.id);
        this.$root.$emit("open-event-modal", ["Winter Event"]);
        this.$bvModal.hide("modal-news-center");
        return;
      }

      switch (this.entry.title) {
        case "news.mission.complete.title":
          this.$store.dispatch("news/markNewsAsDeleted", this.entry.id);
          this.$root.$emit("mission-accomplished");
          this.$bvModal.hide("modal-news-center");
          break;
        case "news.ingameevent.activated.title":
          this.$store.dispatch("news/markNewsAsDeleted", this.entry.id);
          this.$root.$emit("open-event-modal", this.entry.payload);
          this.$bvModal.hide("modal-news-center");
          break;
        case "news.ingameevent.reward.title":
          this.$store.dispatch("news/markNewsAsDeleted", this.entry.id);
          this.$root.$emit("open-event-modal", this.entry.payload);
          this.$bvModal.hide("modal-news-center");
          break;
        default:
          return;
      }
    },
  },
};
</script>
<style lang="scss">
.menu-list-entry {
  position: relative;

  .news-entry {
    height: 130px;
    background: url(~@/assets/img/notification/bg-standard-entry.png)
      center/100% 100% no-repeat;

    .news-icon {
      max-width: 100px;
      max-height: 100px;
    }

    .news-text {
      text-align: left;

      .headline {
        .green-text {
          color: #43ff00;
          text-transform: uppercase;
          font-size: 24px;
          font-family: Ubuntu-Bold;
        }

        .normal-text {
          font-size: 24px;
          font-family: Ubuntu-Bold;
          line-height: 1;
          margin-bottom: 10px;
        }
      }

      .subtext {
        margin-top: 10px;
        font-size: 20px;
        font-family: Ubuntu;
        line-height: 1;
        padding-right: 20px;
        &.smol {
          font-size: 16px;
        }
      }
    }
  }

  .news-closer {
    position: absolute;
    right: 0px;
    top: -7px;
    width: 32px;
    height: 32px;
    background: url(~@/assets/img/notification/btn-close-entry.png) center/100%
      100% no-repeat;
    z-index: 99;
    cursor: pointer;
  }
}
</style>
